import NotFoundComponent from "@/views/NotFoundComponent.vue"

export let testsRoute = { 
  path: "/tests",
  component: () => import("./TestRouter.vue"),
  children: [
    {
      path: "test-webrtc",
      component: () => import("./TestWebRTC.vue"),
    },
    {
      path: "event-flow",
      component: () => import("./TestEventFlow.vue")
    },
    {
      path: "test-s",
      component: () => import("./TestS.vue")
    },
    {
      path: "test-s-iospoc",
      component: () => import("./TestSiOSPoC.vue")
    },
    {
      path: "test-s-iospoc2",
      component: () => import("./TestSiOSPoC2.vue")
    },
    {
      path: "test-s2",
      component: () => import("./TestS2.vue")
    },
    {
      path: "test-s3",
      component: () => import("./TestS3.vue")
    },
    {
      path: "test-room",
      component: () => import("./TestRoom.vue")
    },
    {
      path: "test-roomlogs",
      component: () => import("./TestRoomLogs.vue")
    },
    {
      path: "test-spectrum",
      component: () => import("./TestSpectrum.vue")
    },
    {
      path: "test-spectrum2",
      component: () => import("./TestSpectrum2.vue")
    },
    {
      path: "multi-guests/:eventId",
      component: () => import("./TestMultiGuests.vue")
    },
    {
      path: "functions",
      component: () => import("./TestFunctions.vue")
    },
    {
      path: "form-ios",
      component: () => import("./TestFormiOS.vue")
    },
    {
      path: "ios-loop",
      component: () => import("./TestiOSLoop.vue")
    },
    {
      path: "webrtc-ios",
      component: () => import("./TestWebRTCiOS.vue")
    },
    {
      path: "landing-page",
      component: () => import("./TestLandingPage.vue")
    },
    {
      path: "functions-performance",
      component: () => import("./TestFunctionsPerformance.vue")
    },
    {
      path: "test-utils",
      component: () => import("./TestUtils.vue")
    },
    {
      path: "error",
      component: () => import("./TestError.vue")
    },
    {
      path: "test-studio",
      component: () => import("./TestStudio.vue")
    },
    {
      path: "test-composer-assets-override",
      component: () => import("./TestComposerAssetsOverride.vue")
    },
    {
      path: "test-gum",
      component: () => import("./TestGUM.vue")
    },
    {
      path: "test-streaming",
      component: () => import("./TestStreaming.vue")
    },
    {
      path: "test-hls",
      component: () => import("./TestHLS.vue")
    },
    {
      path: "test-mime-types",
      component: () => import("./TestMimeTypes.vue")
    },
    {
      path: "test-canvg",
      component: () => import("./TestCanVG.vue")
    },
    {
      path: "test-composition",
      component: () => import("./TestComposition.vue")
    },
    {
      path: "test-composer",
      component: () => import("./TestComposer.vue")
    },
    {
      path: "test-audio",
      component: () => import("./TestAudio.vue")
    },
    {
      path: "test-ffmpeg-hls",
      component: () => import("./TestFFMPEGHLS.vue")
    },
    {
      path: "test-msb",
      component: () => import("./TestMSB.vue")
    },
    {
      path: "test-ui",
      component: () => import("./TestUI.vue")
    },
    {
      path: "test-preview-gum",
      component: () => import("./TestPreviewGUM.vue")
    },
    {
      path: "test-broadcast",
      component: () => import("./TestBroadcast.vue")
    },
    {
      path: "test-sfu",
      component: () => import("./TestSFU.vue")
    },
    {
      path: "test-template-editor",
      component: () => import("./TestTemplateEditor.vue")
    },
    {
      path: "test-gum-component",
      component: () => import("./TestGUMComponent.vue")
    },
    {
      path: "test-event-settings",
      component: () => import("./TestEventSettings.vue")
    },
    {
      path: "test-slots",
      component: () => import("./TestSlots.vue")
    },
    {
      path: "test-users",
      component: () => import("./TestUsers.vue")
    },
    {
      path: "test-pixel-streaming",
      component: () => import("./TestPixelStreaming.vue")
    },    
    {
      path: "test-builder",
      component: () => import("./TestBuilder.vue")
    },
    {
      path: "test-builder/:pageid",
      component: () => import("./TestBuilder.vue")
    },
    {
      path: "test-survey",
      component: () => import("./TestSurvey.vue")
    },
    {
      path: "test-survey/:surveyid",
      component: () => import("./TestSurvey.vue")
    },
    {
      path: "test-survey-builder",
      component: () => import("./TestSurveyBuilder.vue")
    },
    {
      path: "test-survey-builder/:surveyid",
      component: () => import("./TestSurveyBuilder.vue")
    },
    {
      path: "test-um-recorder",
      component: () => import("./TestUMRecorder.vue")
    },
    {
      path: "test-msb-player",
      component: () => import("./TestMSBPlayer.vue")
    },
    {
      path: "test-fn-stats-island",
      component: () => import("./TestFNStatsIsland.vue")
    },
    {
      path: "test-carousel",
      component: () => import("./TestCarousel.vue")
    },
    {
      path: "test-electron-exec",
      component: () => import("./TestElectronExec.vue")
    },
    {
      path: '*', 
      component: NotFoundComponent 
    }
  ]
}
