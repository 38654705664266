
import NotFoundComponent from "@/views/NotFoundComponent.vue"

export let sgetRoute = { 
  path: "/sget",
  component: () => import("./SgetRouter.vue"),
  children: [
    {
      path: "list",
      meta: { noFooter: true },
      component: () => import("./List.vue"),
    },
    {
      path: "login",
      meta: { nextRoute: "/sget/list" },
      component: () => import("../fnstats/Login.vue"),
    },
    {
      path: '*',
      component: NotFoundComponent 
    }
  ]
}



